import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import AdminNavbar from "./pages/AdminNavBar";
import Dashboard from "./components/Dashboard";
import AdminLogin from "./components/AdminLogin";
import AddLink from "./components/AddLink";
import Profile from "./components/Profile.jsx";
import Page404 from "./components/Page404.jsx";
import Settings from "./components/Settings.jsx";
import Domain from "./components/Domain.jsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AdminNavbar />}>
          <Route index element={<Dashboard />} />
          <Route path="links" element={<AddLink />} />
          <Route path="profile" element={<Profile />} />
          <Route path="settings" element={<Settings />} />
          <Route path="domain" element={<Domain />} />
        </Route>
        <Route path="login" element={<AdminLogin />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
