import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Links from "./Links";

function AddLink() {
  const [links, setLinks] = useState([]);
  const [link, setLink] = useState("");
  const [publicUrl, setPublicUrl] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get("/admin/data");
        setPublicUrl(res.data.data.clientUrl);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUsers();
  }, []);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get("/admin/links");
        setLinks(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUsers();
  }, []);
  const addLink = async () => {
    if (link) {
      const loading = toast.loading("Creating link...");
      try {
        const res = await axios.post("/admin/create-link", {
          link,
        });
        toast.success("Link created....", { id: loading });
        setLinks(res.data.data);
        setLink("");
      } catch (error) {
        toast.error(error.response.data.message, { id: loading });
        setLink("");
      }
    }
  };

  const handleDelete = async (id) => {
    const loading = toast.loading("Deleting links...");
    try {
      const res = await axios.get(`/admin/delete-link`);
      toast.success("Links deleted....", { id: loading });
      setLinks(res.data.data);
    } catch (error) {
      toast.error(error.response.data.message, { id: loading });
    }
  };


  return (
    <div>
      <div className="my-20 mx-auto">
        <div className="w-1/2 mx-auto">
          <input
            type="text"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            placeholder="Give Link suffix"
            className="bg-transparent w-full dark:text-white px-2 border outline-none border-gray-600 rounded h-10"
          />
          <button
            onClick={addLink}
            className="dark:text-white mx-auto mt-2 block h-10 bg-orange-500 rounded w-full md:w-24"
          >
            Add
          </button>
        </div>
      </div>
      <div className="block md:flex w-full">
        <div className="flex justify-between w-full px-5">
          <h1 className="dark:text-white text-2xl px-5">
            All Links({links.length})
          </h1>
          <button
            onClick={() => handleDelete()}
            className="bg-red-500 rounded text-black py-1 mb-1 px-5"
          >
            Delete all
          </button>
        </div>
      </div>
      <Links links={links} public_url={publicUrl} />
    </div>
  );
}

export default AddLink;
