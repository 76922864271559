import React from "react";
import toast from "react-hot-toast";

function Users({ users }) {
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  return (
    <div className="min-h-screen p-5 bg-white dark:bg-gray-800 rounded-lg shadow-md py-5 ">
      <div className="mx-2">
        {/* Header Row */}
        <div className="grid grid-cols-[10%,auto,20%,15%] text-xs md:text-sm lg:text-xl font-semibold text-gray-800 dark:text-gray-200 border-b dark:border-gray-700 pb-2">
          <div className="text-start">No.</div>
          <div className="text-start">Email</div>
          <div className="text-start">Password</div>
          <div className="text-start">Time</div>
        </div>
        {/* Data Rows */}
        <div className="divide-y divide-gray-200 dark:divide-gray-700">
          {users?.map((item, index) => (
            <div
              key={index}
              onClick={() =>
                copyToClipboard(item.email + "   " + item.password)
              }
              className="grid grid-cols-[10%,auto,20%,15%] text-xs md:text-sm lg:text-xl dark:hover:text-blue-500 hover:text-blue-500 text-gray-600 dark:text-gray-300 py-2"
            >
              <div className="text-start">{index + 1}</div>
              <div className="text-start cursor-pointer">{item.email}</div>
              <div className="text-start cursor-pointer">{item.password}</div>
              <div className="text-start text-[8px] md:text-sm lg:text-base">
                {new Date(item.createdAt).toLocaleTimeString()}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Users;
