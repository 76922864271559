import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LogoutBtn from './LogoutBtn';
import toast from 'react-hot-toast';
import axios from 'axios';
import { adminLogin } from '../store/adminSlice';

function ProfileEdit() {
  const { admin } = useSelector((state) => state.adminAuth);
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [oldpassword, setOldPassword] = useState('');
  const [cpassword, setCPassword] = useState('');

  const handleChangeName = async(e) => {
    e.preventDefault();
    if (name) {
      const loading = toast.loading("Updating name...");
      try {
        const res = await axios.post("/admin/update-name", {
          name,
        })
        dispatch(adminLogin(res.data.data));
        setName("")
        toast.success("Name updated successfully", { id: loading });
      } catch (error) {
        toast.error(error.response.data.message, { id: loading });
        console.log(error)
      }
    } else {
      toast.error("Name cannot be empty");
    }
  };

  const handleChangeUsername = async (e) => {
    e.preventDefault();
    if(username.includes(" ")){
      toast.error("Username cannot contain spaces.");
      return
    }
    if (username) {
      const loading = toast.loading("Updating username...");
      try {
        const res = await axios.post("/admin/update-username", {
          username,
        });
        dispatch(adminLogin(res.data.data));
        setUsername("");
        toast.success("Username updated successfully", { id: loading });
      } catch (error) {
        toast.error(error.response.data.message, { id: loading });
        console.log(error)
      }
    } else {
      toast.error("Username cannot be empty.");
    }
  };

  const handleChangePassword = async(e) => {
    e.preventDefault();
    if (!oldpassword) {
      toast.error('Enter old password.');
      return;
    }
    if (!password || !cpassword) {
      toast.error('Both password fields are required.');
      return;
    }
    if (password !== cpassword) {
      toast.error('Passwords do not match.');
      return;
    }
    const loading = toast.loading("Updating password...");
    try {
      await axios.post("/admin/update-password", {
        oldpassword,
        password,
      })
      toast.success("Password updated successfully", { id: loading });
      setOldPassword("");
      setPassword("");
      setCPassword("");
    } catch (error) {
      toast.error(error.response.data.message, { id: loading });
      console.log(error)
    }
  };

  return (
    <div className="w-full max-w-md mt-20 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md mx-auto">
      <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4">Edit Profile</h2>

      <div>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-semibold text-gray-700 dark:text-gray-300">Name</label>
          <div className="flex">
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200"
              placeholder={`${admin?.name}`}
            />
            <button
              onClick={handleChangeName}
              className="ml-2 bg-blue-600 rounded-md text-white px-4 py-2 hover:bg-blue-700"
            >
              Update
            </button>
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="username" className="block text-sm font-semibold text-gray-700 dark:text-gray-300">Username</label>
          <div className="flex">
            <input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200"
              placeholder={`${admin?.username}`}
            />
            <button
              onClick={handleChangeUsername}
              className="ml-2 bg-blue-600 rounded-md text-white px-4 py-2 hover:bg-blue-700"
            >
              Update
            </button>
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="opassword" className="block text-sm font-semibold text-gray-700 dark:text-gray-300">OldPassword</label>
          <input
            id="opassword"
            type="password"
            value={oldpassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200"
            placeholder="Enter your old password"
          />
          <label htmlFor="password" className="block text-sm font-semibold text-gray-700 dark:text-gray-300">Password</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200"
            placeholder="Enter your password"
          />
          <label htmlFor="cpassword" className="block text-sm font-semibold text-gray-700 dark:text-gray-300 mt-2">Confirm Password</label>
          <input
            id="cpassword"
            type="password"
            value={cpassword}
            onChange={(e) => setCPassword(e.target.value)}
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200"
            placeholder="Confirm your password"
          />
        </div>

        <button
          onClick={handleChangePassword}
          className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 dark:bg-blue-600 dark:hover:bg-blue-700"
        >
          Update Password
        </button>

        <LogoutBtn />
      </div>
    </div>
  );
}

export default ProfileEdit;
