import { useState } from "react";
import {
  ChartBarIcon,
  Bars3Icon,
  XMarkIcon,
  LinkIcon,
  UserIcon,
  WrenchIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";

import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import DarkMode from "../components/DarkMode";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";

const AdminNavbar = () => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { isAdminLoggedIn } = useSelector((state) => state.adminAuth);
  useEffect(() => {
    if (!isAdminLoggedIn) {
      navigate("/login");
    }
  }, [isAdminLoggedIn, navigate]);


  return (
    <div className="flex h-screen bg-gray-100 dark:bg-slate-900">
      <Toaster position="top-center" />
      {/* Fixed sidebar for desktop */}
      <div className="hidden md:flex flex-col w-64 bg-white dark:bg-gray-900 h-screen fixed">
        <div className="flex items-center justify-between px-4 py-4 border-b dark:border-gray-700">
          <span className="text-2xl font-bold text-gray-900 dark:text-white">
            <Link to={"/"}>
              <img src="/logo.png" alt="" className="h-6" />
            </Link>
          </span>
          <DarkMode />
        </div>
        <nav className="flex-grow px-4 py-6">
          <NavLink to="/" className={`flex mb-3 items-center !text-green-600`}>
            <ChartBarIcon className="h-6 w-6 mr-2" />
            Dashboard
          </NavLink>
          <hr className=" opacity-50 mb-5" />
          <NavLink
            to="/links"
            className="flex mb-3 items-center text-blue-600"
          >
            <LinkIcon className="h-6 w-6 mr-2" />
            Links
          </NavLink>
          <NavLink
            to="/profile"
            className="flex mb-3 items-center text-blue-600"
          >
            <UserIcon className="h-6 w-6 mr-2" />
            Profile
          </NavLink>
          <NavLink
            to="/domain"
            onClick={() => setSidebarOpen(false)}
            className="flex mb-3 items-center text-blue-600"
          >
            <GlobeAltIcon className="h-6 w-6 mr-2" />
            Chance Domain
          </NavLink>
          <NavLink
            to="/settings"
            onClick={() => setSidebarOpen(false)}
            className="flex mb-3 items-center text-blue-600"
          >
            <WrenchIcon className="h-6 w-6 mr-2" />
            Settings
          </NavLink>
        </nav>
      </div>

      {/* Mobile navbar */}
      <div className="md:hidden flex items-center w-full bg-white dark:bg-gray-900 px-4 py-3 border-b dark:border-gray-700 fixed z-10">
        <span className="text-xl font-bold text-gray-900 dark:text-white">
          <Link to={"/"}>
            <img src="./logo.png" alt="" className="h-6" />
          </Link>
        </span>
        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className="text-gray-900 dark:text-white mx-2 focus:outline-none"
        >
          {sidebarOpen ? (
            <XMarkIcon className="h-6 w-6" />
          ) : (
            <Bars3Icon className="h-6 w-6" />
          )}
        </button>
        <DarkMode />
      </div>

      {/* Sidebar overlay for mobile */}
      {sidebarOpen && (
        <div className="md:hidden fixed inset-0 top-16 z-40 flex">
          <div className="w-64 bg-white dark:bg-gray-900 h-screen shadow-lg">
          <nav className="flex-grow px-4 py-6">
          <NavLink to="/" onClick={() => setSidebarOpen(false)} className={`flex mb-3 items-center !text-green-600`}>
            <ChartBarIcon className="h-6 w-6 mr-2" />
            Dashboard
          </NavLink>
          <hr className=" opacity-50 mb-5" />
          <NavLink
            to="/links"
            onClick={() => setSidebarOpen(false)}
            className="flex mb-3 items-center text-blue-600"
          >
            <LinkIcon className="h-6 w-6 mr-2" />
            Links
          </NavLink>
          <NavLink
            to="/profile"
            onClick={() => setSidebarOpen(false)}
            className="flex mb-3 items-center text-blue-600"
          >
            <UserIcon className="h-6 w-6 mr-2" />
            Profile
          </NavLink>
          <NavLink
            to="/domain"
            onClick={() => setSidebarOpen(false)}
            className="flex mb-3 items-center text-blue-600"
          >
            <GlobeAltIcon className="h-6 w-6 mr-2" />
            Chance Domain
          </NavLink>
          <NavLink
            to="/settings"
            onClick={() => setSidebarOpen(false)}
            className="flex mb-3 items-center text-blue-600"
          >
            <WrenchIcon className="h-6 w-6 mr-2" />
            Settings
          </NavLink>
        </nav>
          </div>
          <div
            className="flex-grow bg-black bg-opacity-50"
            onClick={() => setSidebarOpen(false)}
          />
        </div>
      )}

      {/* Main content */}
      <div className="flex-grow ml-0 md:ml-64">
        <div className="pt-16 md:pt-0 w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
