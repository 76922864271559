import React from "react";
import toast from "react-hot-toast";

function Links({ links, public_url }) {
  const copyToClipboard = async (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  return (
    <>
      <div className="w-full min-h-screen p-5 bg-white dark:bg-gray-800 rounded-lg shadow-md py-5 mx-auto">
        {/* Header Row */}
        <div className="grid grid-cols-[10%,auto,20%,15%] text-sm font-semibold text-gray-800 dark:text-gray-200 border-b dark:border-gray-700 pb-2">
          <div className="text-start">No.</div>
          <div className="text-start">Link</div>
          <div className="text-start">Action</div>
          <div className="text-start">Time</div>
        </div>
        {/* Data Rows */}
        <div className="divide-y divide-gray-200 dark:divide-gray-700">
          {links?.map((item, index) => (
            <div
              key={index}
              className="grid grid-cols-[10%,auto,20%,15%] text-sm md:text-base lg:text-lg text-gray-600 dark:text-gray-300 py-2"
            >
              <div className="text-start">{index + 1}</div>
              <div className="text-start cursor-pointer hover:text-blue-500">
                {public_url + "/" + item.link}
              </div>
              <div className="text-start cursor-pointer hover:text-blue-500">
                <button
                  onClick={() => copyToClipboard(public_url + "/" + item.link)}
                  className="bg-blue-500 rounded text-black py-1 px-2"
                >
                  Copy
                </button>
              </div>
              <div className="text-start">
                {new Date(item.createdAt).toLocaleTimeString()}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Links;
