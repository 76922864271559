import React, { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

function Settings() {
  const [email, setEmail] = useState('');
  const [image1, setImage1] = useState(null)
  const [image2, setImage2] = useState(null)
  const [image3, setImage3] = useState(null)

  // Handle file input change
  const handleFile1Change = (e) => {
    setImage1(e.target.files[0]);
  };
  const handleFile2Change = (e) => {
    setImage2(e.target.files[0]);
  };
  const handleFile3Change = (e) => {
    setImage3(e.target.files[0]);
  };
   const handleImage1 = async()=>{
    if(image1){
      const formData = new FormData();
      formData.append('step1', image1);
      const loading = toast.loading("Updating..")
      try {
        const response = await axios.post('/admin/change/step1', formData);
        console.log(response.data);
        toast.success("Image uploaded successfully",{id: loading});
        setImage1(null)
      } catch (error) {
        toast.error(error.response.data.message, {id: loading});
      }
    }
   };
   const handleImage2 = async()=>{
    if(image2){
      const formData = new FormData();
      formData.append('step2', image2);
      const loading = toast.loading("Updating..")
      try {
        const response = await axios.post('/admin/change/step2', formData);
        console.log(response.data);
        toast.success("Image uploaded successfully",{id: loading});
        setImage1(null)
      } catch (error) {
        toast.error(error.response.data.message, {id: loading});
      }
    }
   };
   const handleImage3 = async()=>{
    if(image3){
      const formData = new FormData();
      formData.append('step3', image3);
      const loading = toast.loading("Updating..")
      try {
        const response = await axios.post('/admin/change/step3', formData);
        console.log(response.data);
        toast.success("Image uploaded successfully",{id: loading});
        setImage1(null)
      } catch (error) {
        toast.error(error.response.data.message, {id: loading});
      }
    }
   };
   const handleEmail = async()=>{
    if(email){
      const loading = toast.loading("Updating..")
      try {
        const response = await axios.post('/admin/change/email', {email});
        console.log(response.data);
        toast.success("Email updated successfully",{id: loading});
        setEmail("")
      } catch (error) {
        toast.error(error.response.data.message, {id: loading});
      }
    }
   };
   
  return (
    <div className="p-4 md:p-8 bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl text-red-500 dark:text-red-400 border-b-2 border-gray-200 dark:border-gray-700 pb-2">
          Settings
        </h1>
      </div>
      <div className="mt-5 text-center space-y-4">
        <div>
          <label htmlFor="email" className="block text-lg font-semibold mb-2">
            Email
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border border-gray-300 dark:border-gray-700 rounded px-3 py-2 w-2/3 md:w-1/3 focus:outline-none focus:ring focus:ring-blue-500 dark:focus:ring-blue-700 bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-100"
            placeholder="Enter your email"
          />
          <button
            onClick={handleEmail}
            className="ml-3 px-4 py-2 bg-blue-500 dark:bg-blue-700 text-white rounded hover:bg-blue-600 dark:hover:bg-blue-800"
          >
            Change
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
          <div className="space-y-2">
            <label
              htmlFor="image1"
              className="block text-lg font-semibold text-center"
            >
              Step-1
            </label>
            <input
              id="image1"
              type="file"
              accept="image/*"
              onChange={handleFile1Change}
              className="block mx-auto w-2/3 md:w-full text-gray-700 dark:text-gray-100"
            />
            <button
              onClick={() => {handleImage1()}}
              className="w-full px-4 py-2 bg-green-500 dark:bg-green-700 text-white rounded hover:bg-green-600 dark:hover:bg-green-800"
            >
              Upload
            </button>
          </div>
          <div className="space-y-2">
            <label
              htmlFor="image2"
              className="block text-lg font-semibold text-center"
            >
              Step-2
            </label>
            <input
              id="image2"
              type="file"
              accept="image/*"
              onChange={handleFile2Change}
              className="block mx-auto w-2/3 md:w-full text-gray-700 dark:text-gray-100"
            />
            <button
              onClick={() => {handleImage2()}}
              className="w-full px-4 py-2 bg-green-500 dark:bg-green-700 text-white rounded hover:bg-green-600 dark:hover:bg-green-800"
            >
              Upload
            </button>
          </div>
          <div className="space-y-2">
            <label
              htmlFor="image3"
              className="block text-lg font-semibold text-center"
            >
              Step-3
            </label>
            <input
              id="image3"
              type="file"
              accept="image/*"
              onChange={handleFile3Change}
              className="block mx-auto w-2/3 md:w-full text-gray-700 dark:text-gray-100"
            />
            <button
              onClick={() => {handleImage3()}}
              className="w-full px-4 py-2 bg-green-500 dark:bg-green-700 text-white rounded hover:bg-green-600 dark:hover:bg-green-800"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;

