import React, { useState, useEffect } from "react";
import axios from "axios";
import Users from "./Users";
import {
  CalculatorIcon,
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  LinkIcon,
} from "@heroicons/react/24/solid";
import { useDispatch } from "react-redux";
import { adminLogout } from "../store/adminSlice";
import NotificationIndicator from "./NotificationIndicator";

function Dashboard() {
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get("/admin/user-list");
        const newUsers = res.data?.data;
        setUsers(newUsers);
      } catch (error) {
        console.log(error);
        dispatch(adminLogout());
      }
    };
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 3000); // 3 seconds interval

    return () => clearInterval(intervalId);
  }, [dispatch]);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get("/admin/data");
        setData(res.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 3000); // 3 seconds interval

    return () => clearInterval(intervalId);
  }, [dispatch]);

  const playAudio = () => {
    const audio = new Audio("/audio/alert.mp3");
    audio.play().catch((error) => console.error("Audio play error:", error));
  };

  const handleResetNotifications = async () => {
    try {
      const res = await axios.get("/admin/notification/reset");
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data?.alert === true) {
      playAudio();
      async function resetAlert() {
        try {
          const res = await axios.get("/admin/alert/reset");
          console.log(res.data);
        } catch (error) {
          console.log(error);
        }
      }
      resetAlert();
    }
  }, [data]);

  console.log(data.alert)
  return (
    <div className="dark:bg-gray-900 dark:text-gray-300">
      <NotificationIndicator
        handleClick={handleResetNotifications}
        count={data?.notifications}
      />
      <div className="mt-20 w-full flex space-x-2 justify-center dark:bg-gray-900">
        <div className="flex w-48 md:w-72 bg-[#e6e7e6] dark:bg-gray-950 p-2 rounded-lg mb-1">
          <DevicePhoneMobileIcon width={50} />
          <div className="">
            <h1 className="font-bold text-lg md:text-xl w-full">
              Mobile Clicks
            </h1>
            <p className="font-bold">{data?.mobileClicks || 0}</p>
          </div>
        </div>
        <div className="flex w-48 md:w-72 bg-[#e6e7e6] dark:bg-gray-950 p-2 rounded-lg mb-1">
          <ComputerDesktopIcon width={50} />
          <div className="">
            <h1 className="font-bold text-lg md:text-xl w-full">
              Desktop Clicks
            </h1>
            <p className="font-bold">{data?.desktopClicks || 0}</p>
          </div>
        </div>
      </div>
      <div className="pb-10 w-full flex space-x-2 justify-center">
        <div className="flex w-48 md:w-72 bg-[#e6e7e6] dark:bg-gray-950 p-2 rounded-lg">
          <CalculatorIcon width={50} />
          <div className="">
            <h1 className="font-bold text-lg md:text-xl w-full">
              Total Clicks
            </h1>
            <p className="font-bold">
              {data?.mobileClicks + data?.desktopClicks}
            </p>
          </div>
        </div>
        <div className="flex w-48 md:w-72 bg-[#e6e7e6] dark:bg-gray-950 p-2 rounded-lg">
          <LinkIcon width={50} />
          <div className="">
            <h1 className="font-bold text-lg md:text-xl w-full">
              Email copied
            </h1>
            <p className="font-bold">{data?.copiedEmails || 0}</p>
          </div>
        </div>
      </div>

      <Users users={users} />
    </div>
  );
}

export default Dashboard;
